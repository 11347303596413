import React from "react";
import { FOOTER_TYPE } from "src/constants/layout";
import dynamic from "next/dynamic";
import FooterSimple from "./FooterSimple/FooterSimple";

export interface CustomFooterProps {
  content: any;
  footerType: string;
}

const SundayFooter = dynamic(
  () => import("sunday-component").then((mod) => mod.Footer),
  {
    loading: () => <div />,
  }
);

const RetailFooter = ({
  content,
  footerType,
}: CustomFooterProps): JSX.Element => {
  switch (footerType) {
    case FOOTER_TYPE.MENU:
      return <SundayFooter company="TH-SDB" />;
    case FOOTER_TYPE.SIMPLE:
      return <FooterSimple content={content} />;
    default:
      return <></>;
  }
};

export default RetailFooter;
