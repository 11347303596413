export const ACCEPT_ALL_PATTERN = /[\s\S]*/;
export const TH_MOBILE_NO_PATTERN = /0([689]\d{8}|[23457]\d{7})$/;
export const ID_MOBILE_NO_PATTERN =
  /^(\(\+62|62|0)(\d{2,3})?\)?[ .-]?\d{2,4}[ .-]?\d{2,5}$/;
export const DECIMAL_PATTERN = /^\d+(?:\.\d+)?$/;
export const TH_CID_PATTERN = /^[1-9]\d{4}\d{5}\d{2}\d$/;
export const ID_CID_PATTERN = /^\d{2}[01237]\d{3}[01234567]\d[01]\d{7}$/;
export const ONE_TO_HUNDRED_PATTERN = /^[1-9]\d?$|^100$/;
export const BANK_ACCOUNT_NO_PATTERN = /^[A-Za-z0-9]+$/;
export const EMAIL_PATTERN =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ONLY_TH_EN_PATTERN = /^[a-zA-Z\u0E00-\u0E7F\s]*$/;
export const EN_NUM_SYMBOL = /^[a-zA-Z0-9-_]*$/;
export const ONLY_EN_NUM_UNDERSCORE_DASH_CHAR_PATTERN = /[^a-zA-Z0-9\-_]/g;
export const ID_RW_PATTERN = /^\d{3}$/;
export const ID_RT_PATTERN = /^\d{3}$/;
export const SPLIT_PATH = /^\/([^/]+)/;
export const YYYY_MM_DD_DATE_PATTERN = /^\d{4}-\d{2}-\d{2}$/;
