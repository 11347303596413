/* eslint-disable consistent-return */
import React from "react";
import dynamic from "next/dynamic";
import {
  IMenuHeaderProps,
  IPartnerHeaderProps,
  IProfileHeaderProps,
  ISimpleHeaderProps,
} from "./IHeader";

const MenuHeader = dynamic(() => import("./MenuHeader/MenuHeader"), {
  loading: () => <div />,
});

const PartnerHeader = dynamic(() => import("./PartnerHeader/PartnerHeader"), {
  loading: () => <div />,
});

const ProfileHeader = dynamic(() => import("./ProfileHeader/ProfileHeader"), {
  loading: () => <div />,
});

const SimpleHeader = dynamic(() => import("./SimpleHeader/SimpleHeader"), {
  loading: () => <div />,
});

const Header = ({
  type,
  ...others
}:
  | IPartnerHeaderProps
  | IMenuHeaderProps
  | IProfileHeaderProps
  | ISimpleHeaderProps): JSX.Element => {
  switch (type) {
    case "menu":
      return <MenuHeader {...(others as IMenuHeaderProps)} />;
    case "profile":
      return <ProfileHeader {...(others as IProfileHeaderProps)} />;
    case "simple":
      return <SimpleHeader {...(others as ISimpleHeaderProps)} />;
    case "partner":
      return <PartnerHeader {...(others as IPartnerHeaderProps)} />;
  }
};

export default Header;
