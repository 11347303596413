import React from "react";
import dynamic from "next/dynamic";

const FooterLite = dynamic(() => import("./FooterLite/FooterLite"), {
  loading: () => <div />,
});

const FooterNtl = dynamic(() => import("./FooterNtl/FooterNtl"), {
  loading: () => <div />,
});

export interface CustomFooterProps {
  content: any;
}

const Footer = ({ content }: CustomFooterProps): JSX.Element => {
  return (
    <>
      {content?.footer?.template === "sunday-retail" && (
        <FooterLite content={content} />
      )}
      {content?.footer?.template === "ntl" && <FooterNtl content={content} />}
    </>
  );
};

export default Footer;
