import { nationality } from "src/utils/master-data";
import {
  TH_MOBILE_NO_PATTERN,
  ID_MOBILE_NO_PATTERN,
  ACCEPT_ALL_PATTERN,
  TH_CID_PATTERN,
  ID_CID_PATTERN,
} from "src/utils/regEx";

export const REGION = (process.env.NEXT_PUBLIC_REGION || "").toUpperCase();

export const REGION_OPTION = {
  TH: "TH",
  ID: "ID",
};

export const CONFIG_BY_REGION: {
  [key: string]: {
    mobileNoPattern: RegExp;
    nationalityForCid: string;
    cidPattern: RegExp;
  };
} = {
  TH: {
    mobileNoPattern: TH_MOBILE_NO_PATTERN,
    nationalityForCid: nationality.THAI.name.code,
    cidPattern: TH_CID_PATTERN,
  },
  ID: {
    mobileNoPattern: ID_MOBILE_NO_PATTERN,
    nationalityForCid: nationality.INDONESIA.name.code,
    cidPattern: ID_CID_PATTERN,
  },
};

export const MOBILE_NO_PATTERN =
  CONFIG_BY_REGION[REGION]?.mobileNoPattern || ACCEPT_ALL_PATTERN;

export const NATIONALITY_FOR_CID = CONFIG_BY_REGION[REGION]?.nationalityForCid;

export const CID_PATTERN =
  CONFIG_BY_REGION[REGION]?.cidPattern || ACCEPT_ALL_PATTERN;
