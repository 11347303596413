import { color } from "src/styles/globals";
import { down } from "styled-breakpoints";
import styled from "styled-components";
import { Container, Body as SundayBody } from "sunday-component";

export const FooterContainer = styled(Container)`
  max-width: 1192px;
  margin: auto;
`;

export const Content = styled.div`
  margin: 24px;
`;

export const TextContainer = styled.div`
  width: max-content;
  ${down("sm")} {
    width: auto;
  }
`;

export const TextBody = styled(SundayBody)`
  color: ${color.charcoal10};
`;

export const TextLinkBody = styled(SundayBody)`
  :hover {
    color: var(--ui-form-input-footer-link-hover-text-color);
  }
`;

export const CopyRightContainer = styled.div`
  width: 210px;
`;
