import { Row } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Image from "next/image";
import { useRouter } from "next/router";
import { color } from "src/styles/globals";
import { down } from "styled-breakpoints";
import styled from "styled-components";
import { Title as SundayTitle } from "sunday-component";

const Container = styled(Row)`
  position: fixed;
  bottom: 16px;
  right: 16px;
  background-color: #06c755;
  height: 48px;
  border-radius: 56px;
  padding: 0px 16px;
  cursor: pointer;
  z-index: 9999;
  ${down("md")} {
    bottom: 12px;
    right: 12px;
    width: 48px;
    padding: 0px;
  }
`;

const Title = styled(SundayTitle)`
  color: ${color.white};
`;

export const LineBubble = () => {
  const text = {
    th: "คุยกับเราผ่าน LINE",
    en: "Chat via LINE ",
  };
  const { locale } = useRouter();
  const { lg } = useBreakpoint();

  return (
    <a href="http://line.me/ti/p/@easysunday">
      <Container align="middle" justify="center">
        <Image
          src="/static/line-bubble-icon.svg"
          height={40}
          width={40}
          alt="line"
        />
        {lg && <Title level={7}>{text[locale]}</Title>}
      </Container>
    </a>
  );
};
