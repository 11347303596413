import { Layout } from "antd";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { useRouter } from "next/router";
import { Header as SundayHeader } from "sunday-component";
import {
  LOGICAL_GROUP_CHANNEL_CODE,
  RETAIL_LOGICAL_GROUP_CHANNEL_CODE,
} from "src/constants/logicalGroupChannelCode";
import { REGION, REGION_OPTION } from "src/constants/region";
import { ChannelConfigType } from "src/types/channelConfig";
import dynamic from "next/dynamic";
import { User } from "src/types/user";
import { down } from "styled-breakpoints";
import { FOOTER_TYPE, HEADER_TYPE } from "src/constants/layout";
import Footer from "../Common/Footer/Footer";
import { HeaderType, Language } from "../Common/Header/IHeader";
import RetailFooter from "../Common/Footer/RetailFooter";
import Header from "../Common/Header";
import { LineBubble } from "./LineBubble";

const { Content } = Layout;

const Container = styled(Layout)`
  min-height: 100vh;
  background: var(--ui-form-background_page-background-color);
`;

const CustomContent = styled(Content)<{ contentBackgroundColor?: string }>`
  min-height: 66.67vh;
  ${({ contentBackgroundColor }) =>
    contentBackgroundColor && `background: ${contentBackgroundColor};`}
  ${down("md")} {
    min-height: fit-content;
  }
`;

const CookieConsent = dynamic(() => import("../CookieConsent/CookieConsent"), {
  ssr: false,
  loading: () => <div />,
});

const SundayIndoFooter = dynamic(
  () => import("sunday-component").then((mod) => mod.IndoFooter),
  {
    loading: () => <div />,
  }
);

export type WithLayoutPropType = {
  channelPath?: string;
  channelConfig: ChannelConfigType;
  children: JSX.Element;
  headerType: HeaderType;
  footerType: string;
  user?: User;
  contentBackgroundColor?: string;
};

export const withLayout = (WrappedComponent) => {
  return (props: WithLayoutPropType) => {
    const {
      channelConfig,
      headerType = HEADER_TYPE.MENU,
      footerType = FOOTER_TYPE.MENU,
      user,
      contentBackgroundColor,
      channelPath: channelPathProps,
    } = props;
    const { content } = channelConfig;
    const router = useRouter();
    const { locale } = router;
    const channelPath =
      typeof router.query.channelPath === "object"
        ? router.query.channelPath?.[0]
        : router.query.channelPath || channelPathProps;
    const isUseSundayLayout =
      LOGICAL_GROUP_CHANNEL_CODE === RETAIL_LOGICAL_GROUP_CHANNEL_CODE ||
      !content;
    // default header menu type for unknown channel, 404, 500 page
    const isUseDefaultHeaderType = !channelPath;
    const onChangeLanguage = (language: any) => {
      if (language !== router.locale) {
        router.replace(router.asPath, router.asPath, { locale: language });
      }
    };
    const availableLanguages = content?.supportedLangs.map((lang) => lang.code);
    const [footer, setFooter] = useState(false);

    useEffect(() => {
      setTimeout(() => {
        setFooter(true);
      }, 800);
    }, []);

    // Dev-note: to prevent /default/404, /default/500 pre-render error on build time
    if (locale === "default") {
      return (
        <CustomContent contentBackgroundColor={contentBackgroundColor}>
          <WrappedComponent {...props} />
        </CustomContent>
      );
    }
    return (
      <>
        <Container>
          {content?.askUsChatbot?.enabled && <LineBubble />}
          {!isUseSundayLayout && (
            <>
              <Header
                showSwitchLanguage
                type="partner"
                onChangeLanguage={onChangeLanguage}
                language={locale as Language}
                theme="white" // 'orange' | 'white' | 'yellow' | 'grey' | 'coolGreyAndWhite'
                content={content}
              />
              <CustomContent contentBackgroundColor={contentBackgroundColor}>
                <WrappedComponent {...props} />
              </CustomContent>
              {!!footer && <Footer content={content} />}
            </>
          )}
          {isUseSundayLayout && (
            <>
              {REGION === REGION_OPTION.TH && (
                <>
                  {headerType === HEADER_TYPE.PROFILE && channelPath && (
                    <Header
                      showSwitchLanguage
                      type={HEADER_TYPE.PROFILE}
                      onChangeLanguage={onChangeLanguage}
                      language={locale as Language}
                      content={content}
                      isLogin={!!user}
                      profile={user?.profile}
                    />
                  )}
                  {(headerType === HEADER_TYPE.MENU ||
                    isUseDefaultHeaderType) && (
                    <Header
                      showSwitchLanguage
                      type={HEADER_TYPE.MENU}
                      onChangeLanguage={onChangeLanguage}
                      language={locale as Language}
                      content={content}
                    />
                  )}
                  {headerType === HEADER_TYPE.SIMPLE && channelPath && (
                    <Header
                      showSwitchLanguage
                      type={HEADER_TYPE.SIMPLE}
                      onChangeLanguage={onChangeLanguage}
                      language={locale as Language}
                      content={content}
                    />
                  )}
                </>
              )}
              {REGION === REGION_OPTION.ID && (
                <SundayHeader
                  showSwitchLanguage
                  type="indo"
                  onChangeLanguage={onChangeLanguage}
                  language={locale as Language}
                  theme="white" // 'orange' | 'white' | 'yellow' | 'grey' | 'coolGreyAndWhite'
                  availableLanguages={availableLanguages}
                />
              )}

              <CustomContent contentBackgroundColor={contentBackgroundColor}>
                <WrappedComponent {...props} />
              </CustomContent>

              {REGION === REGION_OPTION.TH && footer && (
                <RetailFooter content={content} footerType={footerType} />
              )}
              {REGION === REGION_OPTION.ID && footer && (
                <SundayIndoFooter company="ID-SDI" />
              )}
              <CookieConsent />
            </>
          )}
        </Container>
      </>
    );
  };
};
