import { HeaderType } from "src/components/Common/Header/IHeader";

export const HEADER_TYPE: Record<string, HeaderType> = {
  MENU: "menu",
  PARTNER: "partner",
  PROFILE: "profile",
  SIMPLE: "simple",
};

export const FOOTER_TYPE = {
  MENU: "menu",
  SIMPLE: "simple",
};
