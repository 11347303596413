import { Col, Row } from "antd";
import { useRouter } from "next/router";
import {
  Content,
  CopyRightContainer,
  FooterContainer,
  TextBody,
  TextContainer,
  TextLinkBody,
} from "./FooterSimple.style";

export type FooterSimplePropsType = {
  content: any;
};

const FooterSimple = ({ content }: FooterSimplePropsType) => {
  const router = useRouter();
  const { locale } = router;

  return (
    <FooterContainer>
      <Content>
        <Row gutter={[16, 16]} justify="space-between">
          {content?.copyright && (
            <Col>
              <TextContainer>
                <CopyRightContainer>
                  {content?.copyright && (
                    <TextBody level={7}>{content?.copyright}</TextBody>
                  )}
                </CopyRightContainer>
              </TextContainer>
            </Col>
          )}
          {content?.broker_license?.enabled && (
            <Col>
              <TextContainer>
                <a href={content?.broker_license?.link} target="blank">
                  <TextBody level={7}>
                    <TextLinkBody level={7}>
                      <div>{content?.broker_license?.body?.[locale]}</div>
                      <div>{content?.broker_license?.license_no}</div>
                    </TextLinkBody>
                  </TextBody>
                </a>
              </TextContainer>
            </Col>
          )}
          {content?.onlineSellingLicense?.enabled && (
            <Col>
              <TextContainer>
                <a href={content?.onlineSellingLicense?.link} target="blank">
                  <TextBody level={7}>
                    <TextLinkBody level={7}>
                      <div>{content?.onlineSellingLicense?.body?.[locale]}</div>
                      <div>{content?.onlineSellingLicense?.licenseNo}</div>
                    </TextLinkBody>
                  </TextBody>
                </a>
              </TextContainer>
            </Col>
          )}
        </Row>
      </Content>
    </FooterContainer>
  );
};

export default FooterSimple;
